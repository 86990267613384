import React from "react";

//MUI Imports
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Portal from "@material-ui/core/Portal";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

//Supporting imports
import emailjs from "emailjs-com";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

//Component and asset/css import
import "./assets/NewsForm.scss";

const initialContactValues = {
  id: 0,
  contactEmail: "",
  contactName: "",
  clothingCheckbox: false,
  packagingCheckbox: false,
  writingInstrumentsCheckbox: false,
  durableDrinkwareCheckbox: false,
  mobileAudioTechnologyCheckbox: false,
  corporateBrandingCheckbox: false,
  visualGIFCreationCheckbox: false,
};

const useStyles = makeStyles((theme) => ({
  dialog: {
    border: "none",
  },
  inputLabel: {
    color: "#333",
  },
  checkbox: {
    boxColor: "#e57373",
    checkedColor: "#689f38",
    requiredColor: "#0097a7",
    labelColor: "#000000",
  },
  focused: {
    background: "red",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function NewsForm(props) {
  //load in theme override for dialog and its textfields
  const classes = useStyles();

  //Form values state
  const [contactValues, setContactValues] = React.useState(
    initialContactValues
  );

  //State of the alert
  const [openAlert, setOpenAlert] = React.useState(false);

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  //Handle input change by assigning it to the state variable
  const handleFieldTextInputChange = (e) => {
    const { name, value } = e.target;
    setContactValues({
      ...contactValues,
      [name]: value,
    });
  };

  //Handle checkbox change by assigning it to the state variable
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setContactValues({
      ...contactValues,
      [name]: checked,
    });
  };

  //Close dialog
  const handleDialogClose = () => {
    props.onCloseDialog(false);
  };

  //Emailjs form submit handling
  const sendNewsEmail = (e) => {
    e.preventDefault();

    console.log(contactValues);

    emailjs
      .send(
        "service_ecodea",
        "template_f1nda4i",
        contactValues,
        "user_B9oKrlJdV4HYPqSG3PSxH"
      )
      .then(
        (result) => {
          console.log(result.text);
          setOpenAlert(true);
          handleDialogClose();
        },
        (error) => {
          console.log(error.text);
          handleDialogClose();
        }
      );
  };

  return (
    <>
      <Dialog
        open={props.dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="form-dialog-title"
        className={classes.dialog}
        id="formDialogue"
        PaperProps={{
          style: {
            backgroundSize: "cover",
            borderRadius: "0px",
          },
        }}
      >
        <ValidatorForm
          onSubmit={sendNewsEmail}
          onError={(errors) => console.log(errors)}
        >
          <DialogTitle id="form-dialog-title">
            <span className="dialog-title">
              We want to get what's right for you.
              <br />
              Tell us what you’d like to see!
            </span>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={contactValues.clothingCheckbox || false}
                        onChange={handleCheckboxChange}
                        className={classes.checkbox}
                        name="clothingCheckbox"
                      />
                    }
                    label="Clothing"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={contactValues.packagingCheckbox || false}
                        onChange={handleCheckboxChange}
                        name="packagingCheckbox"
                      />
                    }
                    label="Packaging"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          contactValues.writingInstrumentsCheckbox || false
                        }
                        onChange={handleCheckboxChange}
                        name="writingInstrumentsCheckbox"
                      />
                    }
                    label="Writing Instruments"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          contactValues.durableDrinkwareCheckbox || false
                        }
                        onChange={handleCheckboxChange}
                        name="durableDrinkwareCheckbox"
                      />
                    }
                    label="Durable Drinkware"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          contactValues.mobileAudioTechnologyCheckbox || false
                        }
                        onChange={handleCheckboxChange}
                        name="mobileAudioTechnologyCheckbox"
                      />
                    }
                    label="Mobile/Audio Technology"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          contactValues.corporateBrandingCheckbox || false
                        }
                        onChange={handleCheckboxChange}
                        name="corporateBrandingCheckbox"
                      />
                    }
                    label="Corporate Branding"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          contactValues.visualGIFCreationCheckbox || false
                        }
                        onChange={handleCheckboxChange}
                        name="visualGIFCreationCheckbox"
                      />
                    }
                    label="Visual GIF Creation"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextValidator
                  variant="filled"
                  label="Email"
                  name="contactEmail"
                  className={classes.textfield}
                  InputProps={{ className: classes.input }}
                  InputLabelProps={{ className: classes.inputLabel }}
                  value={contactValues.contactEmail}
                  onChange={handleFieldTextInputChange}
                  fullWidth
                  validators={[
                    "required",
                    "isEmail",
                    "matchRegexp:^(?!.*(gmail|hotmail|yahoo|bluewin|ike.me|zoho)).*$",
                  ]}
                  errorMessages={[
                    "this field is required",
                    "email is not valid",
                    "gmail addresses not accepted",
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextValidator
                  variant="filled"
                  label="Company Name"
                  name="contactName"
                  className={classes.textfield}
                  InputProps={{ className: classes.input }}
                  InputLabelProps={{ className: classes.inputLabel }}
                  value={contactValues.contactName}
                  onChange={handleFieldTextInputChange}
                  fullWidth
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              className="newsSubmitButton"
              variant="contained"
              type="submit"
            >
              <Typography variant="button" display="block">
                Send
              </Typography>
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
      <Portal>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={openAlert}
          autoHideDuration={6000}
          onClose={handleAlertClose}
        >
          <Alert onClose={handleAlertClose} severity="success">
            Our team has been notified of your interests.
          </Alert>
        </Snackbar>
      </Portal>
    </>
  );
}
