import React from "react";

import SectionBrand from "./SectionBrand";
import SectionExpertise from "./SectionExpertise";
import SectionDownload from "./SectionDownload";
import SectionForm from "./SectionForm";

//Component and asset/css import
import "./assets/Sections.scss";

export default function Sections() {
  return (
    <>
      <div id="sectionsContainer">
        <SectionBrand />

        <SectionExpertise />

        <SectionDownload />

        <SectionForm />
      </div>
    </>
  );
}
