import React from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import ContactForm from "./forms/ContactForm";
import NewsForm from "./forms/NewsForm";

//theme
import { ThemeProvider } from "@material-ui/core/styles";
import { newsTheme, quoteTheme } from "./../../theme";

//Component and asset/css import
import "./assets/SectionForm.scss";
import instagram1 from "./assets/img/sectionExpertise/instagram-1.jpg";
import instagram2 from "./assets/img/sectionExpertise/instagram-2.jpg";
import instagram3 from "./assets/img/sectionExpertise/instagram-3.jpg";
import instagram4 from "./assets/img/sectionExpertise/instagram-4.jpg";
import instagram5 from "./assets/img/sectionExpertise/instagram-5.jpg";
import instagram6 from "./assets/img/sectionExpertise/instagram-6.jpg";

// import Button from "@material-ui/core/Button";
//import Typography from "@material-ui/core/Typography";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faInstagram, faSpotify } from "@fortawesome/free-brands-svg-icons";

export default function SectionForm() {
  //set form state variables
  const [ContactDialogOpen, setContactDialogOpen] = React.useState(false);
  const [NewsDialogOpen, setNewsDialogOpen] = React.useState(false);

  const handleDialogClose = () => {
    setContactDialogOpen(false);
    setNewsDialogOpen(false);
  };

  const instagramClickHandler = () => {
    window.open("https://www.instagram.com/ecodea_ch/?hl=en");
  };

  return (
    <>
      <Container maxWidth="xl" id="sectionForm" className="section">
        <Grid container alignItems="center" justify="center">
          <Grid item container xs={12} md={6}>
            <Typography variant="h3" gutterBottom>
              <Box fontWeight="fontWeightBold">
                Let's start something <br /> completely new together
                <span className="blinking-cursor">_</span>
              </Box>
            </Typography>
            <ThemeProvider theme={newsTheme}>
              <Button
                id="newsButton"
                color="primary"
                variant="contained"
                onClick={() => setNewsDialogOpen(true)}
              >
                Your preferences
              </Button>
            </ThemeProvider>
            <NewsForm
              dialogOpen={NewsDialogOpen}
              onCloseDialog={handleDialogClose}
            />
            <div id="quoteContainerBox">
              <ThemeProvider theme={quoteTheme}>
                <Button
                  id="quoteButton"
                  variant="contained"
                  color="primary"
                  onClick={() => setContactDialogOpen(true)}
                >
                  Get a free quote
                </Button>
              </ThemeProvider>
            </div>

            <ContactForm
              dialogOpen={ContactDialogOpen}
              onCloseDialog={handleDialogClose}
            />
          </Grid>
          <Grid id="instagramImageGridItem" item xs={12} sm={12} md={6}>
            <p id="instagramHandle" onClick={instagramClickHandler}>
              Follow us on IG
            </p>
            <div className="instagramGallery">
              <figure className="gallery__item gallery__item--1">
                <img
                  className="instagram-image"
                  src={instagram1}
                  alt="Instagram 1"
                />
              </figure>
              <figure className="gallery__item gallery__item--2">
                <img
                  className="instagram-image"
                  src={instagram2}
                  alt="Instagram 2"
                />
              </figure>
              <figure className="gallery__item gallery__item--3">
                <img
                  className="instagram-image"
                  src={instagram3}
                  alt="Instagram 3"
                />
              </figure>
              <figure className="gallery__item gallery__item--4">
                <img
                  className="instagram-image"
                  src={instagram4}
                  alt="Instagram 4"
                />
              </figure>
              <figure className="gallery__item gallery__item--5">
                <img
                  className="instagram-image"
                  src={instagram5}
                  alt="Instagram 5"
                />
              </figure>
              <figure className="gallery__item gallery__item--6">
                <img
                  className="instagram-image"
                  src={instagram6}
                  alt="Instagram 6"
                />
              </figure>
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
