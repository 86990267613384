import React from "react";

//Component and asset/css import
import "./assets/Header.scss";

//MUI imports
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";

//Visual assets import
import Logo from "../common/Logo";
import trustpilotLogo from "./../../assets/img/Trustpilot logo.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faBars, faTimes } from "@fortawesome/pro-light-svg-icons";

export default function Header() {
  //Drawer State
  const [drawerState, setDrawerState] = React.useState(false);

  //Close dialog
  const handleDrawerOpen = () => {
    setDrawerState(true);
  };

  //Close dialog
  const handleDrawerClose = () => {
    setDrawerState(false);
  };

  //Menu Click Handlers
  const expertiseClickHandler = () => {
    // Scroll to a certain element
    var element = document.getElementById("sectionExpertise");
    var elementPosition =
      window.pageYOffset + element.getBoundingClientRect().top;
    console.log(elementPosition);

    window.scrollTo({
      top: elementPosition - 100,
      block: "start",
      behavior: "smooth",
    });

    setTimeout(function () {
      handleDrawerClose();
    }, 500);
  };

  const catalogueClickHandler = () => {
    // Scroll to a certain element
    var element = document.getElementById("sectionDownload");
    var elementPosition =
      window.pageYOffset + element.getBoundingClientRect().top;
    console.log(elementPosition);

    window.scrollTo({
      top: elementPosition - 100,
      block: "start",
      behavior: "smooth",
    });

    setTimeout(function () {
      handleDrawerClose();
    }, 500);
  };

  const instagramClickHandler = () => {
    const newWindow = window.open(
      "https://www.instagram.com/ecodea_ch/?hl=en",
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };

  const bannerClickHandler = () => {
    const newWindow = window.open(
      "mailto:hello@ecodea.ch?subject=Catalog Request&body=I'd like to receive the new catalog"
    );
    if (newWindow) newWindow.opener = null;
  };

  const trustpilotClickHandler = () => {
    const newWindow = window.open(
      "https://www.trustpilot.com/review/ecodea.ch",
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };

  const quoteFormClickHandler = () => {
    console.log("scroll now");
    // Scroll to a certain element
    document.getElementById("quoteButton").click();
    handleDrawerClose();
  };

  return (
    <>
      <div className="scrolling-text" onClick={bannerClickHandler}>
        <p>
          HOODIES MADE IN PORTUGAL | LEADTIME 2/3 WEEKS | PRICE FROM CHF15 /
          €15.45 / $15.95 | HOODIES MADE IN PORTUGAL | LEADTIME 2/3 WEEKS |
          PRICE FROM CHF15 / €15.45 / $15.95 | HOODIES MADE IN PORTUGAL |
          LEADTIME 2/3 WEEKS | PRICE FROM CHF15 / €15.45 / $15.95 | HOODIES MADE
          IN PORTUGAL | LEADTIME 2/3 WEEKS | PRICE FROM CHF15 / €15.45 / $15.95
          |
        </p>
        <p>
          HOODIES MADE IN PORTUGAL | LEADTIME 2/3 WEEKS | PRICE FROM CHF15 /
          €15.45 / $15.95 | HOODIES MADE IN PORTUGAL | LEADTIME 2/3 WEEKS |
          PRICE FROM CHF15 / €15.45 / $15.95 | HOODIES MADE IN PORTUGAL |
          LEADTIME 2/3 WEEKS | PRICE FROM CHF15 / €15.45 / $15.95 | HOODIES MADE
          IN PORTUGAL | LEADTIME 2/3 WEEKS | PRICE FROM CHF15 / €15.45 / $15.95
          |
        </p>
      </div>
      <Grid
        className="headerGrid"
        container
        alignItems="center"
        justify="center"
      >
        <Hidden smDown>
          <Grid item xs container justify="flex-start">
            <Button
              className={"header-text-link"}
              color="primary"
              onClick={instagramClickHandler}
            >
              <Typography
                style={{ fontSize: "1rem", fontWeight: "bold" }}
                variant="button"
              >
                Instagram
              </Typography>
            </Button>
            <p className="linkSeperator">|</p>
            <Button
              className={"header-text-link"}
              color="primary"
              onClick={trustpilotClickHandler}
            >
              <img
                src={trustpilotLogo}
                id="trustpilotLogo"
                alt="Trustpilot Review Platform Link"
              />
            </Button>
          </Grid>
        </Hidden>
        <Grid item xs>
          <Logo />
        </Grid>
        <Grid item xs>
          <Grid container justify="flex-end">
            <Hidden smDown>
              <Grid item xs container justify="flex-end">
                <Hidden smDown>
                  <Button
                    className={"header-text-link"}
                    color="primary"
                    onClick={expertiseClickHandler}
                  >
                    <Typography
                      style={{ fontSize: "1rem", fontWeight: "bold" }}
                      variant="button"
                      display="block"
                    >
                      Expertise
                    </Typography>
                  </Button>
                  <p className="linkSeperator">|</p>
                  <Button
                    className={"header-text-link"}
                    color="primary"
                    onClick={catalogueClickHandler}
                  >
                    <Typography
                      style={{ fontSize: "1rem", fontWeight: "bold" }}
                      variant="button"
                      display="block"
                    >
                      Catalogue PDF
                    </Typography>
                  </Button>
                </Hidden>
              </Grid>
            </Hidden>
            <Grid item xs={3} sm={3} md={3} lg={2} container justify="flex-end">
              <Button
                className={"drawer-button"}
                color="primary"
                onClick={handleDrawerOpen}
              >
                <FontAwesomeIcon
                  className="drawer-button-icon"
                  icon={faBars}
                  color="#333"
                />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Drawer anchor="right" open={drawerState} onClose={handleDrawerClose}>
        <FontAwesomeIcon
          className="drawer-close"
          icon={faTimes}
          onClick={handleDrawerClose}
        />
        <div className="drawerListContainer">
          <ul>
            <li>
              <button onClick={expertiseClickHandler}>Expertise</button>
            </li>
            <li>
              <button onClick={catalogueClickHandler}>Catalogue PDF</button>
            </li>
            <li>
              <button onClick={quoteFormClickHandler}>Get a free quote</button>
            </li>
            <li>
              <button onClick={expertiseClickHandler}>Clients</button>
            </li>
          </ul>
        </div>
        <p className="drawer-copyright">&#169; ecodea</p>
      </Drawer>
    </>
  );
}
