import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";

import { ParallaxProvider } from "react-scroll-parallax";

//Components and asset/css imports
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";
import Sections from "./components/sections/Sections";
import Intro from "./components/intro/Intro";
//import {Footer} from './components/footer/Footer';
import "./App.scss";
import "animate.css/animate.min.css";

//Pose Animation imports
import { Helmet } from "react-helmet";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ParallaxProvider>
        <div className="App">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Ecodea - Merchandizing and Branding</title>
          </Helmet>
          <Header />
          <Intro />
          <Sections />
          <Footer />
        </div>
      </ParallaxProvider>
    </ThemeProvider>
  );
}

export default App;
