import React from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Parallax } from "react-scroll-parallax";

//Component and asset/css import
import "./assets/SectionExpertise.scss";
import partnerLogitech from "./assets/img/sectionExpertise/partner_logitech.svg";
import partnerRimmel from "./assets/img/sectionExpertise/partner_rimmel.svg";
import partnerSwissquote from "./assets/img/sectionExpertise/partner_swissquote.svg";
import partnerHipp from "./assets/img/sectionExpertise/partner_hipp.svg";
import partnerDaisyLove from "./assets/img/sectionExpertise/partner_daisylove.svg";

export default function SectionTwo() {
  return (
    <>
      <Container maxWidth="xl" id="sectionExpertise" className="section">
        <Grid container justify="center" alignItems="center" spacing={4}>
          <Box clone order={{ xs: 2, md: 1 }}>
            <Grid item xs={12} sm={12} md={6}>
              <div id="sectionExpertiseContent">
                <Parallax
                  y={["0px", "-50px"]}
                  x={[0, -5]}
                  slowerScrollRate
                  key={1}
                >
                  <div id="sectionBoxOne"></div>
                </Parallax>
                <Parallax x={[0, 10]} slowerScrollRate key={2}>
                  <div id="sectionBoxTwo"></div>
                </Parallax>
                <Parallax
                  y={["0px", "-50px"]}
                  x={[0, 5]}
                  slowerScrollRate
                  key={3}
                >
                  <div id="sectionBoxThree"></div>
                </Parallax>
                <div id="sectionBoxFour">
                  <Typography variant="h3">What we do</Typography>

                  <Typography variant="h5" gutterBottom>
                    Corporate Materials
                  </Typography>

                  <ul className="expertiseContentList">
                    <li>
                      <Typography variant="body1">Clothing</Typography>
                    </li>
                    <li>
                      <Typography variant="body1">Packaging</Typography>
                    </li>
                    <li>
                      <Typography variant="body1">
                        Office Accessories
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1">Durable Drinkware</Typography>
                    </li>
                    <li>
                      <Typography variant="body1">
                        Mobile/Audio Technology
                      </Typography>
                    </li>
                  </ul>
                  <Typography variant="h5" gutterBottom>
                    Including
                  </Typography>
                  <ul className="expertiseContentList">
                    <li>
                      Handling, logistic, storage space and international
                      delivery
                    </li>
                  </ul>
                  <Typography variant="h5" gutterBottom>
                    We can also do these requests
                  </Typography>
                  <ul className="expertiseContentList">
                    <li>
                      <Typography variant="body1">
                        Corporate Branding
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1">Graphic Design</Typography>
                    </li>
                  </ul>
                </div>
              </div>
            </Grid>
          </Box>
          <Box clone order={{ xs: 1, md: 2 }}>
            <Grid item xs={12} sm={12} md={6}>
              <div className="sectionExpertiseTextContentContainer">
                <Typography variant="h3" gutterBottom>
                  <Box fontWeight="fontWeightBold">
                    Creativity is at the core of everything we do.
                  </Box>
                </Typography>
                <Typography variant="body1" gutterBottom>
                  That's why we define ourselves as a creative provider. Our
                  expertises range from
                  <span>
                    {" "}
                    producing high quality design and emotional items{" "}
                  </span>
                  to marketing and distribution.
                </Typography>

                <Typography variant="body1" gutterBottom>
                  Our clients are from diverse countries, cultures and
                  industries, from small local businesses to big international
                  corporations.
                </Typography>
              </div>

              <Grid id="partnerList" container>
                
                <Grid item xs={12}>
                  <p className="clientHeading">some of our clients</p>
                </Grid>
                <Grid item xs={6} sm>
                  <img className="clientImage" alt="Logitech Partner Logo" src={partnerLogitech} />
                </Grid>
                <Grid item xs={6} sm>
                  <img className="clientImage" alt="Swissquote Partner Logo" src={partnerSwissquote} />
                </Grid>
                <Grid item xs={6} sm>
                  <img className="clientImage" alt="Hipp Partner Logo" src={partnerHipp} />
                </Grid>
                <Grid item xs={6} sm>
                  <img className="clientImage" alt="Daisy Love Partner Logo" src={partnerDaisyLove} />
                </Grid>
                <Grid item xs={6} sm>
                  <img className="clientImage" alt="Rimmel Partner Logo" src={partnerRimmel} />
                </Grid>
                <Grid item xs={12}>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Container>
    </>
  );
}
