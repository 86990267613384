import React from "react";

import Grid from "@material-ui/core/Grid";

import logo from "./../../assets/img/ecodea-logo-white.svg";

//Component and asset/css import
import "./assets/Footer.scss";

export default function Footer() {
  return (
    <>
      <div id="footerContainer">
        <Grid container alignItems="center" justify="center">
          <Grid item sm={12} md container justify="flex-start">
            <div id="footerLogoContainer">
              <img src={logo} id="ecodeaLogoFooter" alt="logo" />
            </div>

            <p id="footerLogoTagline">Leave your brand in our hands to create the best.</p>
          </Grid>
          <Grid item sm={12} md container>
            <div id="footerLinks">
              <a href="https://www.ecodea.ch/Ecodea-Merchandising.vcf">VCARD</a>
              <span>|</span>
              <a href="https://www.ecodea.ch/Ecodea-Merchandising.vcf">
                CONTACT
              </a>
            </div>
          </Grid>
          <Grid item sm={12} md container justify="flex-end"></Grid>
        </Grid>

        <div id="footerSeperator"></div>
        <p className="ecodeaCopyright">&#169; ecodea</p>
      </div>
    </>
  );
}
