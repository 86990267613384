import React from "react";

//MUI Imports
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Portal from "@material-ui/core/Portal";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
//import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

//Supporting imports
import emailjs from "emailjs-com";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

//Component and asset/css import
import "./assets/ContactForm.scss";

// import Button from "@material-ui/core/Button";
//import Typography from "@material-ui/core/Typography";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faInstagram, faSpotify } from "@fortawesome/free-brands-svg-icons";

const initialContactValues = {
  id: 0,
  companyName: "",
  companyEmail: "",
  companyPhone: "",
  companyProject: "",
  companyMessage: "",
};

const useStyles = makeStyles((theme) => ({
  dialog: {
    border: "none",
    boxRadius: "0",
    backgroundImage:
      "https://cdn.mos.cms.futurecdn.net/wtqqnkYDYi2ifsWZVW2MT4-970-80.jpg.webp",
  },
}));

export default function ContactForm(props) {
  const classes = useStyles();

  //Form state
  const [contactValues, setContactValues] = React.useState(
    initialContactValues
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContactValues({
      ...contactValues,
      [name]: value,
    });
  };

  const handleDialogClose = () => {
    props.onCloseDialog(false);
  };

  //Form alert state
  const [openAlert, setOpenAlert] = React.useState(false);

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  //Emailjs form submit handling
  const sendQuoteEmail = (e) => {
    e.preventDefault();

    console.log(contactValues);

    emailjs
      .send(
        "service_ecodea",
        "template_vmkc02b",
        contactValues,
        "user_B9oKrlJdV4HYPqSG3PSxH"
      )
      .then(
        (result) => {
          console.log(result.text);
          setOpenAlert(true);
          handleDialogClose();
        },
        (error) => {
          console.log(error.text);
          handleDialogClose();
        }
      );
  };

  return (
    <>
      <Dialog
        open={props.dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="form-dialog-title"
        className={classes.dialog}
        id="formDialogue"
        PaperProps={{
          style: {
            backgroundColor: "#fff",
            borderRadius: "0px",
          },
        }}
      >
        <ValidatorForm
          onSubmit={sendQuoteEmail}
          onError={(errors) => console.log(errors)}
        >
          <DialogTitle id="form-dialog-title">
            Contact us and receive a free quote
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              To receive a quote, please fill in the form below.
            </DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextValidator
                  label="Company Name"
                  name="companyName"
                  value={contactValues.companyName}
                  onChange={handleInputChange}
                  fullWidth
                  validators={["required"]}
                  errorMessages={["Company name is required"]}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextValidator
                  label="Business Email"
                  name="companyEmail"
                  value={contactValues.companyEmail}
                  onChange={handleInputChange}
                  fullWidth
                  validators={[
                    "required",
                    "isEmail",
                    "matchRegexp:^(?!.*(gmail|hotmail|yahoo|bluewin|ike.me|zoho)).*$",
                  ]}
                  errorMessages={[
                    "Email is required",
                    "Please enter a valid email",
                    "gmail addresses not accepted",
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextValidator
                  label="Phone"
                  name="companyPhone"
                  value={contactValues.companyPhone}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextValidator
                  label="Project"
                  name="companyProject"
                  value={contactValues.companyProject}
                  onChange={handleInputChange}
                  fullWidth
                  validators={["required"]}
                  errorMessages={["Project is required"]}
                />
              </Grid>
              <Grid item xs={12}>
                <br />
                <TextValidator
                  label="Describe the project briefly"
                  placeholder="(Quantity, delivery time etc...)"
                  name="companyMessage"
                  rows="3"
                  variant="outlined"
                  value={contactValues.companyMessage}
                  onChange={handleInputChange}
                  multiline
                  rowsMax={8}
                  fullWidth
                  validators={["required"]}
                  errorMessages={["Description is required"]}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              className="newsSubmitButton"
              variant="contained"
              type="submit"
            >
              <Typography variant="button" display="block">
                Send
              </Typography>
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
      <Portal>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={openAlert}
          autoHideDuration={6000}
          onClose={handleAlertClose}
        >
          <Alert onClose={handleAlertClose} severity="success" className="successAlertColor">
            Thank you, we will contact you as soon as possible.
          </Alert>
        </Snackbar>
      </Portal>
    </>
  );
}
