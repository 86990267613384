import { createMuiTheme } from "@material-ui/core/styles";

const defaultTheme = createMuiTheme();
const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#545454",
      main: "#014273",
      dark: "#000000",
      contrastText: "#ffffff",
    },
    secondary: {
      light: "#ffedd6",
      main: "#014273",
      dark: "#9b8b76",
      contrastText: "#000000",
    },
    error: {
      main: "#774545",
    },
  },
  checkbox: {
    boxColor: "#ffffff",
    checkedColor: "#5d4037",
    labelColor: "#ff5722",
  },
  textField: {
    focusColor: "#00b8d4",
    textColor: "#607d8b",
    hintColor: "#607d8b",
    floatingLabelColor: "#607d8b",
    backgroundColor: "#795548",
    borderColor: "#dce775",
  },
  typography: {
    fontFamily: ["Open Sans", "Arial", "sans-serif"].join(", "),
    h1: {
      fontFamily: ["Prata", "urw-antiqua", "serif"].join(", "),
    },
    h2: {
      fontFamily: ["Prata", "urw-antiqua", "serif"].join(", "),
    },
    h3: {
      fontFamily: ["Prata", "urw-antiqua", "serif"].join(", "),
      [defaultTheme.breakpoints.up("xs")]: {
        fontSize: "2.2rem",
      },
      [defaultTheme.breakpoints.up("sm")]: {
        fontSize: "2.8rem",
      },
      [defaultTheme.breakpoints.up("md")]: {
        fontSize: "2.8rem",
      },
      [defaultTheme.breakpoints.up("lg")]: {
        fontSize: "3.4rem",
      },
    },
    h4: {
      fontFamily: ["Prata", "urw-antiqua", "serif"].join(", "),
    },
    button: {
      fontFamily: "Open Sans",
      color: "#000",
      textTransform: "uppercase",
    },
  },
});

const newsTheme = createMuiTheme({ palette: { primary: { main: "#000" } } });
const quoteTheme = createMuiTheme({
  palette: { primary: { main: "#000" } },
});
export { theme as default, newsTheme, quoteTheme };
