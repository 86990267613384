import React from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Flip from "react-reveal/Flip";
import { Parallax, useController } from "react-scroll-parallax";

//Component and asset/css import
import "./assets/SectionBrand.scss";
import image1 from "./assets/img/img1.png";
import image2 from "./assets/img/img-2.jpg";
import image3 from "./assets/img/img-3.jpg";
import image4 from "./assets/img/img-4.jpg";

export default function SectionOne() {
  //Needed to refresh scroll positions after loading
  const { parallaxController } = useController();

  const handleImageLoaded = () => {
    parallaxController.update();
  };

  return (
    <>
      <Container maxWidth="xl" id="sectionBrand" className="section">
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12} sm={12} md={6}>
            <div className="sectionOneTextContentContainer">
              <Flip bottom>
                <Typography variant="h3" gutterBottom>
                  <Box fontWeight="fontWeightBold">
                    Leave your brand in our hands to create the best.
                  </Box>
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Ecodea will guide you through the creation process of your
                  next
                  <span> merchandising and custom-branded </span>
                  items with a primary focus on sustainable materials made in
                  Switzerland or Europe.
                </Typography>
              </Flip>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} align="center">
            <div className="sectionImgContentContainer">
              <Parallax y={["-100px", "0px"]} slowerScrollRate key={1}>
                <img
                  id="subSectionOneImgOne"
                  className="sub-section-img"
                  src={image1}
                  onLoad={handleImageLoaded}
                  alt=""
                />
              </Parallax>
              <Parallax x={["100px", "0px"]} slowerScrollRate key={2}>
                <img
                  id="subSectionOneImgTwo"
                  className="sub-section-img"
                  src={image2}
                  onLoad={handleImageLoaded}
                  alt=""
                />
              </Parallax>
              <Parallax y={["200px", "0px"]} slowerScrollRate key={3}>
                <img
                  id="subSectionOneImgThree"
                  className="sub-section-img"
                  src={image3}
                  onLoad={handleImageLoaded}
                  alt=""
                />
              </Parallax>
              <Parallax x={["-100px", "0px"]} slowerScrollRate key={4}>
                <img
                  id="subSectionOneImgFour"
                  className="sub-section-img"
                  src={image4}
                  onLoad={handleImageLoaded}
                  alt=""
                />
              </Parallax>
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
