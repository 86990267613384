import React from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import MuiAlert from "@material-ui/lab/Alert";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from "@material-ui/core/styles";

//Supporting imports
import emailjs from "emailjs-com";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Parallax } from "react-scroll-parallax";

//Icon Imports
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faDownload } from "@fortawesome/pro-light-svg-icons";

// styles and assets imports
import "./assets/SectionDownload.scss";
import image1 from "./assets/img/img-download-1.jpg";
import image2 from "./assets/img/img-download-2.jpg";

const useStyles = makeStyles((theme) => ({
  dialog: {
    border: "none",
    backgroundImage:
      "https://cdn.mos.cms.futurecdn.net/wtqqnkYDYi2ifsWZVW2MT4-970-80.jpg.webp",
  },
  inputLabel: {
    color: "#333",
  },
  checkbox: {
    boxColor: "#e57373",
    checkedColor: "#689f38",
    requiredColor: "#0097a7",
    labelColor: "#000000",
    fontSize: "12px"
  },
  checkboxLabel: {
    fontSize: 12
  },
  focused: {
    background: "red",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function SectionDownload() {
  //load in theme override for dialog and its textfields
  const classes = useStyles();

  //Form values state
  const [catalogueEmail, setCatalogueEmail] = React.useState();
  const [newsletterAcceptance, setNewsletterScceptance] = React.useState();

  //Handle input change by assigning it to the state variable
  const handleFieldTextInputChange = (e) => {
    const { value } = e.target;
    setCatalogueEmail(value);
  };

  //State of the alert
  const [openAlert, setOpenAlert] = React.useState(false);

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  //Emailjs form submit handling
  const sendCatalogueEmail = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_ecodea",
        "template_pdfbru6",
        { email: catalogueEmail, newsletter: newsletterAcceptance },
        "user_B9oKrlJdV4HYPqSG3PSxH"
      )
      .then(
        (result) => {
          setOpenAlert(true);
          setTimeout(function () {
            setOpenAlert(false);
          }, 8000);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <Container maxWidth="xl" id="sectionDownload" className="section">
        <Grid container alignItems="center" justify="center">
          <Grid item xs={12} sm={12} md={6}>
            <Typography variant="h3" className="download-header" gutterBottom>
              Request our new catalog.
            </Typography>
            <Typography variant="body1" className="download-body" gutterBottom>
              Everything we do is in the name of creating new possibilities.
              This is the culmination of it all, our pursuit of the next
              interesting idea for your company, our relentless and ongoing
              innovation mainly captured in this catalog and videos. If
              creativity is an art, then objects and clothes are our mediums.
            </Typography>
            <div id="catalogueFormContainer">
              <ValidatorForm
                onSubmit={sendCatalogueEmail}
                onError={(errors) => console.log(errors)}
              >
                <TextValidator
                  variant="standard"
                  label="Enter Business Email"
                  name="contactName"
                  className={classes.textfield}
                  InputProps={{
                    className: classes.input,
                    endAdornment: (
                      <button id="catalogueSubmitButton" type="submit">
                        {">"}
                      </button>
                    ),
                  }}
                  InputLabelProps={{ className: classes.inputLabel }}
                  value={catalogueEmail}
                  onChange={handleFieldTextInputChange}
                  fullWidth
                  validators={[
                    "required",
                    "isEmail",
                    "matchRegexp:^(?!.*(gmail|hotmail|yahoo|bluewin|ike.me|zoho)).*$",
                  ]}
                  errorMessages={[
                    "this field is required",
                    "email is not valid",
                    "Please use a business email",
                  ]}
                />
                <FormControlLabel control={
                  <Checkbox 
                    checked={newsletterAcceptance || false}
                    onChange={e => {setNewsletterScceptance(e.target.checked)}}
                    className={classes.checkbox}
                    name="clothingCheckbox" 
                  />
                } label="I would also like to receive the newsletter on a regular basis." 
                classes={{
                  label:classes.checkboxLabel
                }} 
              />

              </ValidatorForm>

              <Alert
                id="downloadSuccessAlert"
                onClose={handleAlertClose}
                severity="success"
                className={!openAlert && "alertShow"}
              >
                Your request has been successfully transmitted
              </Alert>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} align="center">
            <div className="sectionImgContentContainer">
              <Parallax y={["0px", "0px"]} slowerScrollRate key={1}>
                <img
                  id="downloadImgOne"
                  className="download-img"
                  src={image1}
                  alt=""
                />
              </Parallax>
              <Parallax x={["100px", "0px"]} slowerScrollRate key={2}>
                <img
                  id="downloadImgTwo"
                  className="download-img"
                  src={image2}
                  alt=""
                />
              </Parallax>
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
