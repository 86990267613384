import React from "react";
import ReactPlayer from "react-player";

//Component and asset/css import
import "./assets/Intro.scss";

export default function Intro() {
  return (
    <>
      <div id="introWrapper">
        <ReactPlayer
          id="introVideo"
          url="video/intro_video.mp4"
          width="100%"
          height="100%"
          controls={false}
          muted={true}
          playing={true}
          loop={true}
          playsinline={true}
        />
        <div className="icon-scroll"></div>
      </div>
    </>
  );
}
